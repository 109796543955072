import React, { useState ,useRef, useEffect } from 'react';
import Container from '@material-ui/core/Container'
import { Alert, AlertTitle } from '@material-ui/lab';
import Layout from '../core/Layout';


const Signup = () => {
    return (
          <div> 
             <Layout> 
                    <div style={{marginTop: 100}}>
                    <Container maxWidth="xs">
                    <Alert severity="error">
            <AlertTitle>SEED BURSARY application </AlertTitle>
              <p>Application for 2025 now closed</p>
            </Alert> 
                    </Container> 
                    </div>
               </Layout>
        </div>
    );
};

export default Signup;